.profileImage {
    width: 72px;
    height: 72px;
    border-radius: 50%;
}

.profileImageLabel {
    margin: 0;
    position: relative;

    >i {
        position: absolute;
        bottom: -3px;
        right: -3px;
    }
}

.pprofile1 {
    padding: 21px 22px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 12px;
}

p.bg-light.rounded.py-2.fieldcls {
    background-color: #775DA62b !important;

}

.ViewUser {
    // font-weight: 500;
    color: #202020;
    font-weight: 600;
    font-size: 22px;
}


.profileImagebx {
    width: 100%;
    max-width: 100px;
    height: 100%;
    border-radius: 50%;
}

.profile_label {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}
.profile_data_bx {
    font-size: 16px;
    font-weight: 400;
    color: #2020209c;
}