.tox-notifications-container,.tox-statusbar__branding{
    display: none;
}
.linkclass {
    color: #82222E !important;
}
.checkList {
    label {
        margin-right: 14px;
    }
}

.roleForm {
    >* {
        >label {
            font-weight: 600;
        }
    }
}

#questionModal,
#viewQuestionModal {
    .modal-dialog {
        max-width: 900px;
    }
}

.deleteOption {
    color: #f44336;
    cursor: pointer;
}

.box_content .box_view {
        background-color: #82222e1c;
        color: #000;
        padding: 6px 12px;
        border-radius: 7px;
        height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .description_view {
        background-color: #f0e7e8;
        padding: 6px 12px;
        border-radius: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 150px;
        overflow: auto;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    
    .total_description .tox-edit-area .dnXCYb {
        display: none !important;
    }


    .main_modal_bx .modal-title {
        color: #000;
        font-weight: 500;
        font-size: 20px;
    }

    .total_description  .tox.tox-tinymce.tox-tinymce--disabled {
       
    height: 200px !important;
    }

    .total_modal .dnXCYb br {
        display: none !important;
    }
    .total_modal {
        overflow-y: scroll;
        height: 500px;
    }

    .border_absolute {
        position: absolute;
        bottom: 0px;
        right: 42px;
        font-size: 13px;
    }
    .description_view .dnXCYb{
        display: none;
    }

.table_bx tr.heading_row {
    // border: 1px solid #dfe2e6;
}