@import "./mixins";

// Colors
@import "./colors";

td.table_dats {
  text-transform: capitalize;
}
span.badges_status {
  background: #237ab9;
  color: #fff;
  padding: 2px 13px;
  border-radius: 46px;
  margin-right: 2rem;
}
span.badges_reg {
  background: #fff;
  color: #000;
  padding: 2px 13px;
  border-radius: 46px;
  margin-right: 2rem;
}
.d-flex.bg_rotter.justify-content-between {
  background: #3278b91c;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 1rem;
}
img.delete_icon {
  width: 80px;
}
.bg_hover .dropdown-item.active,
.bg_hover .dropdown-item:active {
  background-color: #82222e !important;
  border-radius: 8px;
  color: #ffffff !important;
  padding: 0.25rem 1rem;
  text-decoration: none;
}

.bg_hover .dropdown-item.active,
.bg_hover .dropdown-item:active {
  text-decoration: none;
  background-color: #82222e !important;
  color: #fff !important;
}
.css-13cymwt-control {
  width: 154px;
  margin-top: 4px;
}
.css-t3ipsp-control {
  width: 154px;
  margin-top: 4px;
}
.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  > span {
    font-size: 13px;
    color: #84818a;
  }
}
.chip {
  background: #82222e;
}
.alignTopDate div.ant-picker-input {
  top: -12px;
}
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}
.react-pagination-js-default {
  ul {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 6px 12px;
    gap: 5px;

    > li.page {
      margin: 0px !important;
      font-size: 14px;
      padding: 4px 10px !important ;
      height: 30px ;
      &:first-child {
        padding: 0 !important;
        &:not(.disabled) {
          color: #73242f !important
        }
        &::after {
          content: "< Previous";
          vertical-align: sub;
          padding: 4px 10px;
        }
        a {
          display: none;
        }
      }
      &:last-child {
        padding: 0 !important;
        &:not(.disabled) {
          color: #73242f !important
        }
        &::after {
          content: "Next >";
          vertical-align: sub;
          padding: 4px 10px;
        }
        a {
          display: none;
        }
      }
      &.page {
        border: 1px solid #e0e0e0 ;
        background-color: #fff ;
        box-shadow: none ;
        font-weight: 400 ;
      }

      &.is-active {
        font-weight: 400;
        background-color: #73242f !important;
        border: 1px solid #73242f !important;
        box-shadow: unset;
        text-shadow: unset;
        color: #fff !important;
        padding: 4px 10px !important;
        height: 32px !important;
        margin-right: 0px;
        border-radius: 3px;
      }
    }
  }
}
.pagination_end {
  margin-top: 24px;
}

.pagination_end .react-pagination-js-default .is-active {
  height: 32px;
  padding: 4px 10px;
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  background-color: #73242f !important;
  border: 1px solid #73242f !important;
  box-shadow: unset !important;
  text-shadow: unset;
}
.react-pagination-js-default .is-active{
  height: 32px;
  padding: 4px 10px !important;
  margin: 0px;
  font-size: 16px;
  font-weight: 400 !important;
  background-color: #73242f !important;
  border: 1px solid #73242f !important;
  box-shadow: unset !important;
  text-shadow: unset;
}


body {
  font-family: $font1 !important;
}
a {
  color: #82222e;

  &:hover {
    text-decoration: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}

label {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #000;
}
// .form-row > .col,
// .form-row > [class*="col-"] {
//   padding-right: 10px;
//   padding-left: 5px;
//   align-items: center;
// }
.col_side div span {
  font-size: 16px;
  font-weight: 300;
  // color: #fff;
}
.removeBg {
  padding: 8px 18px !important;
  border-radius: 5px;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
  border-color: #e9e9e9 !important;
  // box-shadow: 0 0 0 2px rgb(255 255 255 / 10%) !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker:hover,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
  border-color: #e4e4e4 !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-range
  .ant-picker-active-bar {
  background: #82222e !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  color: #fff;
  background: #82222e !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #82222e !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: #82222e !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid #82222e !important;
}
i.fa {
  cursor: pointer;
}
.dateRangePicker {
  width: 100% !important;
  border-radius: 8px;
  padding: 21px 10px;
  border: 1px solid #dcdcdc;
  height: calc(1.5em + 0.75rem + 2px);
}

.optionListContainer {
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  position: absolute;
  width: 220px;
  z-index: 9999999;
}
.ccttype {
  border-radius: 8px;
}

.table_data {
  text-transform: capitalize;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: red;
}

.text-truncate {
  width: 250px;
  white-space: nowrap;
  // padding: 20px;
  resize: horizontal;
}

.star {
  color: red !important;
  // display: none;
  // color: #202020;
}

.action_icons {
  display: flex;
}

a.edit_icon {
  // border: 1px solid #DCDCDC;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 0px;
  &:hover {
    text-decoration: none !important;
  }
}

.mainarea {
  background: #f6f6f6;
  padding: 27px;
  height: 100vh !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dcdcdc !important;
  outline: 0;
  box-shadow: none !important;
}

span.edit_icon {
  // border: 1px solid #DCDCDC;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  // margin-left: 3px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
i.fa.fa-eye {
  color: #82222e;
}

i.material-icons.edit {
  font-size: 15px;
  font-weight: 600;
  color: #82222e;
}
i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: #712c2f;
  cursor: pointer;
}
i.fa.fa-arrow-left {
  color: #000;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  // text-transform: capitalize;
  white-space: nowrap;
}

th.table_data {
  border-top: none !important;
  border-bottom: none !important;
  font-size: 14px;
  font-weight: 600 !important;
  color: #25282b !important;
}

body {
  font-family: "Lato", sans-serif;
}

.inputWrapper {
  position: relative;

  // .fa {
  //   position: absolute;
  //   right: 17px;
  //   top: 11px;
  //   color: #d2cece;
  // }
}

.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #84818a !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.dropdown-menu.shadow.bg_hover.show {
  border: none;
  padding: 10px;
}
.css-13cymwt-control {
  border-radius: 40px;
}
.searchWrapper {
  border-radius: 40px;
}
.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a0a4a8;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a4a8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0a4a8;
}

main.main {
  background-color: #f6f6f6 !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
span.bg_bages {
  background: #52b1ff21;
  margin-right: 10px;
  padding: 7px 10px;
  border-radius: 50px;
  color: #56a0dc;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px !important;
  display: inline-block;
}
// Loader end

.btn {
  text-transform: capitalize;
  font-weight: 500;
  padding: 8px 18px;
  font-size: 14px;
  // border-radius: 40px;
}

.btn-outline-primary {
  color: #82222e;
  border-color: #82222e;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

.loginclass {
  // background-image: linear-gradient(186deg, #254c9d, transparent);
  // border-radius: 44px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
  background-color: #d3b750;
  color:#000000d6 !important;
  background-color: #d3b750 !important;
  border: none !important;
}

.loginclass:hover{
  background-color: #d3b750 !important;
  border: none !important;
}

@media (max-width: 768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  > .container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}

.bginput {
  border: 1px solid #e8e8e8 !important;
  // background: #F6F6F6 !important;
  border-radius: 8px;
}
input.form-control.mb-0.bginput.changes {
  background-color: #fff !important;
  border-color: #d2cece !important;
  border: 1px solid #d2cece !important;
  // border-radius: 38px !important;
  height: 40px;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
  color: #d3b750;
}

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #775da6 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #f6f6f6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f6f6f6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f6f6f6;
}

.modal {
  overflow-y: auto;

  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  > input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {
  > li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;
    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }
    }

    &.disabled {
      a {
        border-color: #e8e8e8;
        color: #e8e8e8;
      }
    }
  }
}

.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}

.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #7561a2;
  font-weight: 700;
}
i.fa.fa-trash {
  color: #c02e2e;
  // font-size: 18px;
  font-weight: 700;
}

// Responsive Design
.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.cardList {
  margin-top: 2rem;
}
a.btn.btn-primary.mr-2 {
  white-space: nowrap;
}
.form-control {
  // border-radius: 40px !important;
  // padding: 21px 10px;
  border: 1px solid #dcdcdc;
  // border-radius: 8px;
}
select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
  max-width: 200px;
}
.new_cards {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
  // margin-bottom: 4.2rem;
}
input.searchbar.mr-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}

.d-flex.justify-content-between.align-items-center {
  flex-wrap: wrap;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 12px;
  }
  .dropdown.addDropdown.chnageset {
    margin-top: 12px;
  }

  .sales_section {
    padding: 20px;
  }
  .graph_section {
    padding: 20px;
  }
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1199px) {
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }

  // .dropdown.addDropdown.chnages {
  //   margin-top: 10px;
  // }
  article.d-flex {
    margin-top: 1rem !important;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1000px) {
  .dropdown.addDropdown.chnagesg {
    margin-top: 0px;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }
  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }
  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 25px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }
  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }
  article.d-flex {
    margin-top: 1rem;
  }
  .main-wrapper main.main {
    width: 100% !important;
  }
  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span {
    font-size: 14px;
  }
  span.side_head {
    font-size: 14px;
  }
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 630px) {
  .dropdown.addDropdown.features.mr-2 {
    margin-top: 14px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 621px) {
  .dropdown.addDropdown.chnages {
    margin-top: 15px !important;
  }
  article.d-flex {
    flex-wrap: wrap !important;
    display: flex;
  }
  .dropdown.addDropdown.chnagest_name {
    margin-top: 13px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 615px) {
  //   .dropdown.addDropdown.chnagesname {
  //     margin-top: 15px !important;
  // }
  article.d-flex {
    flex-wrap: wrap !important;
    display: flex;
  }
}

@media screen and (min-width: 601px) and (max-width: 609px) {
  .dropdown.addDropdown.region_currency {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 990px) {
  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }
  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
  }
  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }
  .main-sidebar {
    position: absolute;
    top: 72px;
    // top: 144px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }
  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }
  article.d-flex {
    margin-top: 1rem;
    flex-wrap: wrap;
  }
  .main-wrapper main.main {
    width: 100% !important;
  }
  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .selectDD {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .col-12.col-sm-12.col-md-12.col-lg-6.cls {
    margin-top: 15px;
  }
  span {
    font-size: 14px;
  }
  span.side_head {
    font-size: 14px;
  }
  .dropdown.addDropdown.chnagest {
    margin-left: 15px;
  }
}
@media screen and (max-width: 908px) {
  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}
@media screen and (max-width: 900px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 15px;
  }
  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .text-right {
    margin-bottom: 15px !important;
  }
  .roleTable tr td {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }
  .col-md-9.head_cls {
    margin-bottom: 20px;
  }
  article.d-flex {
    display: flex !important;
    margin-top: 1rem !important;
    flex-wrap: wrap;
  }
  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
  }
}
@media screen and (max-width: 766px) {
  .dropdown.addDropdown.chnages_date {
    margin-top: 15px;
  }
}

@media screen and (max-width: 759px) {
  .dropdown.addDropdown.region_status {
    margin-top: 15px;
  }
}
@media screen and (max-width: 751px) {
  .dropdown.addDropdown.chnagess {
    margin-top: 15px;
  }
  .dropdown.addDropdown.chnageses {
    margin-top: 15px;
  }
}
@media screen and (max-width: 748px) {
  .dropdown.addDropdown.city_region {
    margin-top: 14px;
  }
  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}
@media screen and (max-width: 738px) {
  .dropdown.addDropdown.chnageset {
    margin-top: 15px;
  }
}
@media screen and (min-width:601px) and (max-width: 695px) {
  .icons_tab {
    margin-top: 0px;
  }
}
@media only screen and (min-width: 528px) and (max-width: 600px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
  }
  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }
  .dropdown.addDropdown.chnages_status {
    margin-top: 15px;
  }

  .dropdown.addDropdown {
    // margin-top: 15px;
  }
  button.btn.btn-primary.mr-2.export {
    margin-left: 15px;
  }
  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }
  .icons_tab {
    margin-top: 15px;
  }
  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
  }
  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }
  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }
  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }
  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }
  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  // proffile dropdown
  // .dropdown.ml-auto {
  //   top: -40px;
  // }

  // .headerSearch {
  //   top: 10px !important;
  // }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }
  article.d-flex {
    margin-top: 1rem;
  }
  .d-flex.justify-content-between.mb-3 {
    display: block !important;
  }

  p.mb-0 {
    font-size: 13px;
  }
  img.listIc {
    height: 40px;
    width: 40px;
  }
  form.headerSearch.ml-3 {
    width: auto;
    max-width: 130px;
  }
  // .ml-3 {
  //   width: 300px;
  // }
  span {
    font-size: 14px;
  }
  span.side_head {
    font-size: 14px;
  }
  article.d-flex {
    margin-top: 1rem;
    flex-wrap: wrap;
  }
  button#dropdownMenuButtonstatusDropdown\ reset {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnages {
    margin-top: 15px;
  }
  .dropdown.addDropdown.chnagest {
    margin-top: 15px;
  }
}

@media screen and (max-width: 578px) {
  .dropdown.addDropdown.chnagesapp {
    margin-top: 15px;
  }
}
@media screen and (max-width: 590px) {
  button.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  a.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 527px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
  }
  .icons_tab {
    margin-top: 15px;
  }
  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }
  .main-navbar.min-sidebar {
    // padding-left: 40px;
    display: flex;
  }
  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 40px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }
  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  // proffile dropdown
  .dropdown.ml-auto {
    top: -30px;
    right: -16px;
  }

  .headerSearch {
    top: 20px !important;
  }

  // main p-age

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }
  .nav-tabs {
    border-radius: 3px !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
    margin-top: 1rem;
  }
 
  .icons_tab {
    margin: 0px !important;
  }
  .nav-tabs {
    border-bottom: 1px solid #ffffff;
  }
  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }

  .new_cards {
    height: 100% !important;
    margin-bottom: 0rem !important;
    max-height: 500px !important;
    //  height: 100%;
    //   margin-bottom: 0rem !important;
  }
  li.list_names {
    text-align: left !important;
  }
  .user_proff {
    display: block !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }
  select.form-control.types {
    margin-top: 13px;
    width: 100%;
  }
  i.fa.fa-eye {
    color: #775da6;
  }
  .d-flex.justify-content-between.mb-3 {
    display: block !important;
  }
  .d-flex.justify-content-between {
    display: block !important;
  }

  button.btn.btn-primary.btnreset {
    margin-top: 15px;
  }

  // dashboard

  h2.mb-1.mainHeading_cls {
    font-size: 24px;
    font-weight: 600;
  }
  p.grayCls.mb-0 {
    font-size: 12px;
    font-weight: 200;
    color: #898989;
    width: auto !important;
  }

  a.btn.barlink.text-primary {
    // left: 8px;
    // width: 35px;
    // height: 35px;
    // padding: 7px 9px;
    left: 19px;
    width: 35px;
    height: 35px;
    padding: 7px 9px;
    top: 17px;
  }
  form.headerSearch.ml-3 {
    width: 100% !important;
    margin-left: 9px !important;
  }
  p.mb-0 {
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  // .ml-3 {
  //   width: 200px;
  // }
  img.listIc {
    height: 40px;
    width: 40px;
  }
  .borderBox.d-flex.align-items-center.justify-content-between {
    padding: 0px 15px;
  }
  .timeBoxs p {
    font-size: 14px;
    color: #484848;
    line-height: 17px;
  }

  .headerSearch .Searchbar {
    width: 100%;
    max-width: 130px;
  }
  // form.headerSearch.ml-3 {
  //   width: 130px;
  // }

  // dashboard responsive
  .graph_range {
    display: block;
  }
  p.view_graph {
    margin-top: 18px;
  }

  // admin user
  .d-flex.justify-content-between.align-items-center {
    display: block !important;
  }
  span {
    font-size: 14px;
  }
  span.side_head {
    font-size: 14px;
  }

  article.d-flex a,
  button {
    margin-bottom: 1rem !important;
  }
  button.btn.btn-primary.dropdown-toggle.removeBg {
    margin-bottom: 0px !important;
  }

  .table_section {
    margin-top: 10px !important;
  }
  .mr-2.phBottomSpace {
    margin-bottom: 10px;
  }
  a.btn.btn-primary.profiles {
    width: fit-content;
  }
  button.btn.btn-primary.mr-2.export {
    margin-left: 15px;
  }
  .hedding {
    font-size: 21px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 441px) {
  button.btn.btn-primary.mr-2.exports {
    margin-top: 15px;
  }
  h5.heading_holidays {
    margin-top: 30px !important;
  }
}

// profile changes
input.searchbar.mr-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #7561a2;
}
a.btn.btn-primary.profiles {
  background-color: #fff !important;
  border: 1px solid #c2c2c2 !important;
  height: auto;
  color: #2020209c !important;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
i.material-icons.prob {
  font-size: 18px;
  margin: 0px 7px;
  color: #0000004a;
}
label.btn.btn-primary.delete{
  background-color: #fff !important;
  border-radius: 10px;
  color: #000 !important;
  font-size: 14px ;
  font-weight: 500;
}

label.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}
.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 43px;
  font-weight: 500;
  padding-top: 11px;
}
a.discard {
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;
  & :hover {
    color: #202020 !important;
    font-size: 14px;
    font-weight: 500;
  }
}
.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  // height: 43px;
  border-radius: 10px;
}
button.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}
a.btn.barlink.text-primary:active {
  border: none;
}
p.profile_data {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
}
.col-md-12.inputFlex {
  line-height: 30px;
}
.highlightOption {
  background: #7561a2;
  color: #fff;
}
.multiSelectContainer li:hover {
  background: #7561a2 !important;
  color: #fff;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .min-sidebar ~ div > .main-sidebar a.col_side:hover {
    justify-content: center;
    margin: 8px 14px !important;
  }
  .min-sidebar ~ div > .main-sidebar {
    display: block !important;
    max-width: 250px;
    min-width: 250px;
    width: 100%;
    min-height: 100vh;
    margin-top: -72px;
    border-right: 1px solid #e8e8e8;
  }
  .sidees {
    display: flex;
  }
  .min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: left !important;
    width: auto !important;
  }
  .min-sidebar ~ div > .main-sidebar a.col_side {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    width: auto !important;
  }
  .min-sidebar ~ div > .main-sidebar .nav-link span {
    display: block;
  }
  .min-sidebar ~ div > .main-sidebar a.col_side span {
    display: flex;
    font-size: 15px;
    font-weight: 300;
    color: #202020;
  }
  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 8px !important;
  }
  .main-sidebar {
    display: none;
  }
  .main-sidebar .nav .nav-item .nav-link i.sidenv {
    display: none !important;
  }
  .card.card-body.sides li a {
    border-radius: 4px !important;
  }
}
@media (min-width: 768px) {
  .main-navbar.min-sidebar ~ div > .main-sidebar {
    display: block !important;
  }
}


// New CSS

.settings-inner{
  border: 0;
}
.settings-inner li{
  list-style: none;
  margin: 0 8px;
}

.settings-inner .nav-link,.settings-inner.nav-tabs .nav-link.active {
  color: #fff;
  font-size: 13px;
  background: #82222E;
  border:0;
}
.settings-inner.nav-tabs .nav-link {
  border: 0;
  border-radius: .25rem;
  font-size: 13px;
}
.nav-pills .nav-link {
  text-align: left;
  color: #000;
}
.nav-pills .nav-link.active,.settings-inner.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #82222E !important;
}

.custom-tab {
  width: 70%;

  height: 100vh;
}
.setting-inner {
  width: 30%;
  padding: 10px;
  
 
  background: #fff;
  border-radius: 5PX;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.content-inner h4 {
  font-size: 18px;
  font-weight: 600;
}


.profile_label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}


// change  password  css  

.close_eye {
  position: absolute;
  top: 12px;
  font-size: 14px;
  right: 12px;
}


//users css

.data_row .react-switch-bg {
  background-color: #72252e !important;
}

.profiledetailscls{
  font-size: 14px;
  height: 36px;
  background: #82222e1f;
  padding: 6px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

//setting page

.white-bx {
  background-color: #fff;
  border-radius: 16px;
  margin-top: 24px;
  padding: 0px 20px;
  border: 1px solid #e0e0e0;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.1rem RGB(205 122 140) !important;
}

.bg_img {
  background: url(../../public/assets/img/bg1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
  position: relative;
}
// Tabs Design
.tabsDesign{
  background: #fff;
  width: fit-content;
  border-radius: 5px;
}
.tabsDesign{
  .nav-link{
    font-size: 13px
  }
  .nav-link.active{
    border-bottom: 2px solid #73242f;
  }
} 
.useSearchbar{
  .Searchbar{
  padding: 5px 35px 5px 8px !important;
  background: #fff !important;
  font-size: 13px !important;
 }
 i.fa-search {
    top: 16px !important;
    right: 5px !important;
    font-size: 12px !important;
    left: unset !important;
}
 i.fa-times {
  right: 22px !important;
  top: 16px !important;
  font-size: 12px !important;
}
}
.tableAddBtn{
  font-size: 12px !important;
  padding: 5px !important;
}
.tableAlter{
  width: 200px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}
.table.limitTh th{
  width: 20% !important;
}
.user_hours.Approval{
  background: #00800038 !important;
  width: 135px;
  height: 25px;
  .contract{
    color: #008000e6 !important;
  }
}
.user_hours.Queued.for.Capture{
  span.contract {
    color: #333333d9 !important;
}
}
.profiledetailsclsams{
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  color: #323131;
  background: rgba(130, 34, 46, 0.1098039216) !important;
  padding: 7px 13px !important;
  height: 36px;
  border-radius: 8px;
}

.table thead th{
  white-space: nowrap !important;
}
.multipleBtns .btn {
  /* padding: 5px 7px !important; */
  height: 35px ;
  width: 40px ;
  padding: 0 ;
display: flex;
  align-items: center;
  justify-content: center;
}
.ref_icon{
  height: 100px;
  margin-bottom: 0px;
}
.colog{
  color: #333;
}
textarea.w-100.input-set {
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 3px 7px;
}
p.text-reason {
  margin-bottom: 5px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.chatr_ones {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: 0px solid grey !important;
  border-radius: 12px !important;
}
h5.sales.mb-0 {
  font-size: 20px;
  font-weight: 600;
}
.table-responsive.table_section {
  background: #fff;
  padding: 12px !important;
  border-radius: 12px;
  margin-top: 23px;
  box-shadow: 0px 0px 2px rgba(204, 204, 204, 0.2509803922) !important;
  border: 1px solid #efefef;
}
th.table_data {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  background: #f3f3f3 !important;
  color: #000 !important;
}
td.table_dats {
  border: 1px solid #fff;
}
h3.heading.clr-text {
  font-size: 17px;
  font-weight: 600;
  color: #82222e;
}

h3.heading.clr-text i {
  font-size: 17px;
  font-weight: 600;
  color: #82222e;
}