.top_section{
    // background-image: linear-gradient(to left, #2062AD, #0C8E99, #24A2DB );
    padding: 10px;
    border-radius: 20px;
}

.right_side {
    background-color:#000;
    height: 100%;
    padding: 2rem 2rem;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    // border: 2px solid gray;
    // border-image-source: linear-gradient(to left, #2062AD, #0C8E99, #24A2DB );
}
.main_login{
    // background-image: url('../../../public/assets/img/login_bg.jpg');
    background-color: #000;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;

}
input:-internal-autofill-selected {
    background-color: #fff !important;
    color: #A7A1A1 !important;
    border-color: #D2CECE !important;
    border: 1px solid #D2CECE !important;
    // border-radius: 38px !important;
    height: 40px;
}
.input.form-control.mb-0.bginput{
    background-color: #fff !important;
    color: #A7A1A1 !important;
    border-color: #D2CECE !important;
    border: 1px solid #D2CECE !important;
    border-radius: 38px !important;
    height: 40px;
}